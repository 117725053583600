export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

export const SYNC_ENTRIES_REQUEST = 'SYNC_SNAPSHOT_REQUEST'
export const SYNC_ENTRIES_SUCCESS = 'SYNC_SNAPSHOT_SUCCESS'
export const SYNC_ENTRIES_FAILURE = 'SYNC_SNAPSHOT_FAILURE'

export const ADD_REPLY = 'ADD_REPLY'

export const USER_AUTH_LOGIN = 'USER_AUTH_LOGIN'
export const USER_AUTH_LOGOUT = 'USER_AUTH_LOGOUT'
export const USER_AUTH_UPDATE = 'USER_AUTH_UPDATE'
export const USER_AUTH_ERROR = 'USER_AUTH_ERROR'

export const NOTIFY = 'NOTIFY'
